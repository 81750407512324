<template>
  <Quiz />
</template>

<script>
import Quiz from './components/Quiz.vue'

export default {
  name: 'App',
  components: {
    Quiz,
  }
}
</script>

<style>
@import './assets/main.css';
</style>
